import { default as accountsf875onQMAbMeta } from "/opt/render/project/src/main/pages/accounts.vue?macro=true";
import { default as balancesE0MjRIrevcMeta } from "/opt/render/project/src/main/pages/balances.vue?macro=true";
import { default as indexACFW7aZhzOMeta } from "/opt/render/project/src/main/pages/categories/index.vue?macro=true";
import { default as logintbhIav6H4YMeta } from "/opt/render/project/src/main/pages/login.vue?macro=true";
import { default as mainN93p2t1on1Meta } from "/opt/render/project/src/main/pages/main.vue?macro=true";
import { default as _91id_93fZmIr29w6IMeta } from "/opt/render/project/src/main/pages/operations/[id].vue?macro=true";
import { default as index5ycOcXPJZYMeta } from "/opt/render/project/src/main/pages/operations/index.vue?macro=true";
import { default as tagsaNrzvna1pxMeta } from "/opt/render/project/src/main/pages/tags.vue?macro=true";
import { default as transfers8RLzU8evdMMeta } from "/opt/render/project/src/main/pages/transfers.vue?macro=true";
export default [
  {
    name: accountsf875onQMAbMeta?.name ?? "accounts",
    path: accountsf875onQMAbMeta?.path ?? "/accounts",
    meta: accountsf875onQMAbMeta || {},
    alias: accountsf875onQMAbMeta?.alias || [],
    redirect: accountsf875onQMAbMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/main/pages/accounts.vue").then(m => m.default || m)
  },
  {
    name: balancesE0MjRIrevcMeta?.name ?? "balances",
    path: balancesE0MjRIrevcMeta?.path ?? "/balances",
    meta: balancesE0MjRIrevcMeta || {},
    alias: balancesE0MjRIrevcMeta?.alias || [],
    redirect: balancesE0MjRIrevcMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/main/pages/balances.vue").then(m => m.default || m)
  },
  {
    name: indexACFW7aZhzOMeta?.name ?? "categories",
    path: indexACFW7aZhzOMeta?.path ?? "/categories",
    meta: indexACFW7aZhzOMeta || {},
    alias: indexACFW7aZhzOMeta?.alias || [],
    redirect: indexACFW7aZhzOMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/main/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: logintbhIav6H4YMeta?.name ?? "login",
    path: logintbhIav6H4YMeta?.path ?? "/login",
    meta: logintbhIav6H4YMeta || {},
    alias: logintbhIav6H4YMeta?.alias || [],
    redirect: logintbhIav6H4YMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/main/pages/login.vue").then(m => m.default || m)
  },
  {
    name: mainN93p2t1on1Meta?.name ?? "main",
    path: mainN93p2t1on1Meta?.path ?? "/main",
    meta: mainN93p2t1on1Meta || {},
    alias: mainN93p2t1on1Meta?.alias || [],
    redirect: mainN93p2t1on1Meta?.redirect || undefined,
    component: () => import("/opt/render/project/src/main/pages/main.vue").then(m => m.default || m)
  },
  {
    name: _91id_93fZmIr29w6IMeta?.name ?? "operations-id",
    path: _91id_93fZmIr29w6IMeta?.path ?? "/operations/:id()",
    meta: _91id_93fZmIr29w6IMeta || {},
    alias: _91id_93fZmIr29w6IMeta?.alias || [],
    redirect: _91id_93fZmIr29w6IMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/main/pages/operations/[id].vue").then(m => m.default || m)
  },
  {
    name: index5ycOcXPJZYMeta?.name ?? "operations",
    path: index5ycOcXPJZYMeta?.path ?? "/operations",
    meta: index5ycOcXPJZYMeta || {},
    alias: index5ycOcXPJZYMeta?.alias || [],
    redirect: index5ycOcXPJZYMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/main/pages/operations/index.vue").then(m => m.default || m)
  },
  {
    name: tagsaNrzvna1pxMeta?.name ?? "tags",
    path: tagsaNrzvna1pxMeta?.path ?? "/tags",
    meta: tagsaNrzvna1pxMeta || {},
    alias: tagsaNrzvna1pxMeta?.alias || [],
    redirect: tagsaNrzvna1pxMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/main/pages/tags.vue").then(m => m.default || m)
  },
  {
    name: transfers8RLzU8evdMMeta?.name ?? "transfers",
    path: transfers8RLzU8evdMMeta?.path ?? "/transfers",
    meta: transfers8RLzU8evdMMeta || {},
    alias: transfers8RLzU8evdMMeta?.alias || [],
    redirect: transfers8RLzU8evdMMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/main/pages/transfers.vue").then(m => m.default || m)
  }
]